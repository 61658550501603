var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headerWorld'),_c('div',{staticClass:"header-swiper"},[_c('el-carousel',{staticStyle:{"width":"100%"},attrs:{"interval":2000,"arrow":"always","height":"440px"}},_vm._l((_vm.bannerList),function(item){return _c('el-carousel-item',{key:item},[_c('img',{staticClass:"sipperImage",staticStyle:{"z-index":"-1"},attrs:{"src":item,"alt":""}})])}),1)],1),_c('div',{staticClass:"concat"},[_vm._l((_vm.courseTitleList),function(couseContent){return _c('div',{key:couseContent.categoryName,staticClass:"content-item"},[_c('div',{staticClass:"course-content item-box"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(couseContent.categoryName))]),_c('div',{staticClass:"course-block"},_vm._l((couseContent.courseList),function(item){return _c('div',{key:item.id,staticClass:"course-item",on:{"click":function($event){return _vm.goPage(item)}}},[_c('div',{staticClass:"course-top"},[(item.hasFree === 1)?_c('span',{staticClass:"vip-mark"},[_vm._v("VIP")]):_vm._e(),_c('img',{attrs:{"src":item.image}})]),_c('div',{staticClass:"course-mid"},[_c('div',{staticClass:"limite-line-2"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"course-bot"},[_c('span',[_vm._v("主讲老师: "+_vm._s(item.teacherName))]),_c('el-tag',{attrs:{"size":"mini","type":"danger"}},[_vm._v(_vm._s(item.hasFree === 1 ? "收费" : "免费"))])],1)])}),0)])])}),_vm._m(0),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-box"},[_c('h2',{staticClass:"title"},[_vm._v("学院留言墙")]),_c('div',{staticClass:"container"},[_c('img',{staticClass:"leftarrow",attrs:{"src":"https://www.haixingkt.com/Public/Default/images/bnt-l.png","alt":""},on:{"click":function($event){return _vm.scroll('left')}}}),_c('div',{staticClass:"content",staticStyle:{"padding-bottom":"20px"}},[_c('div',{ref:"viewport",staticClass:"viewport"},[_c('div',{staticClass:"slider",style:({
                    transform: `translateX(${-_vm.currentIndex * _vm.itemWidth}px)`,
                  })},_vm._l((_vm.listprofile),function(item,index){return _c('div',{key:index,staticClass:"slide"},[_c('img',{staticStyle:{"border-radius":"50%","width":"116px","height":"116px","margin-top":"40px"},attrs:{"src":item.image,"alt":""}}),_c('div',{staticStyle:{"color":"#999","font-size":"12px","margin-top":"20px","letter-spacing":"5px"}},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)])]),_c('img',{staticClass:"rightarrow",attrs:{"src":"https://www.haixingkt.com/Public/Default/images/bnt-r.png","alt":""},on:{"click":function($event){return _vm.scroll('right')}}})])])])],2),_c('div',{staticClass:"fixed-sidebar"},[_c('div',{staticClass:"float_dav",on:{"click":function($event){return _vm.handleToDetail(7)}}},[_vm._m(1)]),_c('div',{staticClass:"float_dav",on:{"click":function($event){return _vm.handleToDetail(8)}}},[_vm._m(2)]),_c('div',{staticClass:"float_dav",on:{"click":function($event){return _vm.handleToDetail(9)}}},[_vm._m(3)]),_c('div',{staticClass:"float_dav",on:{"click":function($event){return _vm.handleToDetail(14)}}},[_vm._m(4)]),_vm._m(5),_c('div',{staticClass:"float_dav"},[_vm._m(6),_c('div',[_c('div',{staticStyle:{"font-size":"14px","color":"#666","margin-top":"5px","text-align":"center"}},[_c('div',[_vm._v("官方电话:")]),_c('div',[_vm._v(_vm._s(_vm.rightInfo.phone))])])])])]),_c('footWorld')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-box"},[_c('h2',{staticClass:"title"},[_vm._v("视界只为设计而精彩")]),_c('div',{staticClass:"flex bitteTittle"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://www.vvykt.com/Public/Uploads/image/20201111/20201111152144_29903.jpg","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"font-size":"14px","color":"#666","margin-top":"5px","text-align":"center"}},[_c('div',[_vm._v("关于我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"font-size":"14px","color":"#666","margin-top":"5px","text-align":"center"}},[_c('div',[_vm._v("售后服务")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"font-size":"14px","color":"#666","margin-top":"5px","text-align":"center"}},[_c('div',[_vm._v("投诉建议")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"font-size":"14px","color":"#666","margin-top":"5px","text-align":"center"}},[_c('div',[_vm._v("帮助中心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_dav"},[_c('div',{staticClass:"float_dav"},[_c('img',{staticStyle:{"width":"80%"},attrs:{"src":"http://qiniu.pengfkt.com/FjF_4cnjhKgtIxOM97DYwvkii1KM","alt":""}})]),_c('div',[_c('div',{staticStyle:{"font-size":"14px","color":"#666","margin-top":"5px","text-align":"center"}},[_c('div',[_vm._v("公众号二维码")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_dav"},[_c('img',{staticStyle:{"width":"80%"},attrs:{"src":"http://qiniu.pengfkt.com/wxQrcode.png","alt":""}})])
}]

export { render, staticRenderFns }