<template>
  <div>
    <headerWorld></headerWorld>
    <div class="header-swiper">
      <el-carousel
        style="width: 100%"
        :interval="2000"
        arrow="always"
        height="440px"
      >
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img :src="item" alt style="z-index: -1" class="sipperImage" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="concat">
      <div class="content-item"  v-for="couseContent in courseTitleList" :key="couseContent.categoryName">
        <div class="course-content item-box">
          <h2 class="title">{{ couseContent.categoryName }}</h2>
          <div class="course-block">
            <div
              class="course-item"
              v-for="item in couseContent.courseList"
              :key="item.id"
              @click="
                goPage(item)
              "
            >
              <div class="course-top">
                <span v-if="item.hasFree === 1" class="vip-mark">VIP</span>
                <img :src="item.image" />
              </div>
              <div class="course-mid">
                <div class="limite-line-2">
                  {{ item.name }}
                </div>
              </div>
              <div class="course-bot">
                <span>主讲老师: {{ item.teacherName }}</span>
                <el-tag size="mini" type="danger"
                  >{{ item.hasFree === 1 ? "收费" : "免费" }}</el-tag
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="item-box">
          <h2 class="title">视界只为设计而精彩</h2>
          <div class="flex bitteTittle">
            <img
              src="https://www.vvykt.com/Public/Uploads/image/20201111/20201111152144_29903.jpg"
              alt=""
              style="width: 100%"
            />
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="item-box">
          <h2 class="title">学院留言墙</h2>
          <div class="container">
            <img
              src="https://www.haixingkt.com/Public/Default/images/bnt-l.png"
              alt=""
              @click="scroll('left')"
              class="leftarrow"
            />
            <div class="content" style="padding-bottom: 20px">
              <div class="viewport" ref="viewport">
                <div
                  class="slider"
                  :style="{
                    transform: `translateX(${-currentIndex * itemWidth}px)`,
                  }"
                >
                  <div
                    v-for="(item, index) in listprofile"
                    :key="index"
                    class="slide"
                  >
                    <img
                      :src="item.image"
                      alt=""
                      style="
                        border-radius: 50%;
                        width: 116px;
                        height: 116px;
                        margin-top: 40px;
                      "
                    />
                    <div
                      style="
                        color: #999;
                        font-size: 12px;
                        margin-top: 20px;
                        letter-spacing: 5px;
                      "
                    >
                      {{ item.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="https://www.haixingkt.com/Public/Default/images/bnt-r.png"
              alt=""
              @click="scroll('right')"
              class="rightarrow"
            />
          </div>
        </div>
      </div>
<!--      <div class="content-item">-->
<!--        <div class="item-box">-->
<!--          <h2 class="title">学员作品</h2>-->
<!--          <div class="trainee">-->
<!--            <div class="traineeBox">-->
<!--              <div-->
<!--                v-for="(item, index) in traineeList"-->
<!--                :key="index"-->
<!--                class="flex"-->
<!--                style="margin-bottom: 20px"-->
<!--              >-->
<!--                <img-->
<!--                  :src="item.image"-->
<!--                  alt=""-->
<!--                  style="width: 140px; height: 140px; border-radius: 10px"-->
<!--                />-->
<!--                <div-->
<!--                  style="-->
<!--                    margin-left: 20px;-->
<!--                    text-align: left;-->
<!--                    justify-content: left;-->
<!--                    width: 400px;-->
<!--                  "-->
<!--                  class=" "-->
<!--                >-->
<!--                  <h3 style="margin: 15px 0">{{ item.name }}</h3>-->
<!--                  <p style="color: #999">{{ item.text }}</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="buttontrainee">更多作品</div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <!-- 右下侧固定栏 -->
    <div class="fixed-sidebar">
      <div class="float_dav" @click="handleToDetail(7)">
        <div>
          <div
              style="
              font-size: 14px;
              color: #666;
              margin-top: 5px;
              text-align: center;
            "
          >
            <div>关于我们</div>

          </div>
        </div>
      </div>
      <div class="float_dav" @click="handleToDetail(8)">
        <div>
          <div
              style="
              font-size: 14px;
              color: #666;
              margin-top: 5px;
              text-align: center;
            "
          >
            <div>售后服务</div>

          </div>
        </div>
      </div>
      <div class="float_dav" @click="handleToDetail(9)">
        <div>
          <div
              style="
              font-size: 14px;
              color: #666;
              margin-top: 5px;
              text-align: center;
            "
          >
            <div>投诉建议</div>

          </div>
        </div>
      </div>
      <div class="float_dav" @click="handleToDetail(14)">
        <div>
          <div
              style="
              font-size: 14px;
              color: #666;
              margin-top: 5px;
              text-align: center;
            "
          >
            <div>帮助中心</div>

          </div>
        </div>
      </div>
      <div class="float_dav">
        <div class="float_dav">
          <img src="http://qiniu.pengfkt.com/FjF_4cnjhKgtIxOM97DYwvkii1KM" alt="" style="width: 80%" />
          <!-- <div style="font-size: 14px; color: #666;">
          </div> -->
        </div>
        <div>
          <div
              style="
              font-size: 14px;
              color: #666;
              margin-top: 5px;
              text-align: center;
            "
          >
            <div>公众号二维码</div>
          </div>
        </div>
      </div>
      <div class="float_dav">
        <div class="float_dav">
          <img src="http://qiniu.pengfkt.com/wxQrcode.png" alt="" style="width: 80%" />
          <!-- <div style="font-size: 14px; color: #666;">
          </div> -->
        </div>
        <div>
          <div
              style="
              font-size: 14px;
              color: #666;
              margin-top: 5px;
              text-align: center;
            ">
            <div>官方电话:</div>
            <div>{{ rightInfo.phone }}</div>
          </div>
        </div>
      </div>
    </div>
    <footWorld></footWorld>
  </div>
</template>
<script>
import { getBannerApi, getCourseApi, getRightInfo } from "@/common/api/index";
import footWorld from "@/components/footWorld";
import headerWorld from "@/components/headerWorld.vue";
export default {
  components: {
    footWorld,
    headerWorld,
  },
  data() {
    return {
      rightInfo: {},
      listprofile: [
        {
          image:
            "http://qiniu.pengfkt.com/Fus1gPHGX3EDXnuYUi1ho6jc0jWt",
          text: "偶然间，看到鹏飞课堂，抱着尝试的心里，我学习了设计。在这里经历了太多，认识了很多志同道合的朋友，以及我的另一半。老师们很热情，有问题都能耐心且及时解答，同学之间都是相互学习，相互鼓励进步。临近毕业，老师还是举行了欢送会，感谢鹏飞课堂。",
        },
        {
          image:
            "http://qiniu.pengfkt.com/FuFcEbKetl7JbJXZhCnlTL1C0SX3",
          text: "鹏飞课堂，开启了我的设计之路。从一个小白，到一个能独当一面的设计师，再到设计高手，这一路的走来，离不开鹏飞课堂的帮助。在这里，由衷的感谢张老师，是她手把手教我，从0到1，从1到2。中途无数次想要放弃，同学的鼓励，老师的耐心沟通，让我克服一次次困难，希望所有的同学都能坚持下来。",
        },
        {
          image:
            "http://qiniu.pengfkt.com/FvnIUaSZNUfaydy0nh98nSYlT5GN",
          text: "从事抖音短视频行业，因为非专业，自学的过程中，各种碰壁，进度缓慢。在偶然间接触鹏飞课堂以后，才发现原来专业的跟业余的差距这么大。老师讲课很专业，不懂的随时问，大大节约了我的时间。鹏飞课堂让我以后的工作得心应手，就算是结业了，有不懂的请教老师，老师也会及时解答。",
        },
        {
          image:
            "http://qiniu.pengfkt.com/Fm1dAIOFh8LDcvU1Dsk17uyS9KdN",
          text: "大学毕业，学的设计专业，从事的也是设计专业，因为大学没有好好学，再加上没有实战经验，工作中各种碰壁。后来报班鹏飞课堂，在系统的学习以后，才发现大学不是我学的不够专业，而是讲的不够专业，专业的事还是让专业的人来做，如果你是小白，学姐建议在这里深造一下，让你对设计有不一样的体验。",
        },
        {
          image:
            "http://qiniu.pengfkt.com/FtN_m1q_lMNnJWOGcaEb-z2uAvQg",
          text: "我是高中毕业，在流水线工作，每天日复一日的从事毫无技术的工作，一直想换工作，但是没有一技之长。在鹏飞课堂，我学到了我想要的知识，在抖音如此发达的今天，会一门视频剪辑已经超过很多同龄人。结业以后，我果断选择离职了，入职了一家短视频公司，凭借出色的技术，我拿到了比流水线更高的工资，人也轻松了，感谢鹏飞课堂。",
        },
        {
          image:
            "http://qiniu.pengfkt.com/Ftgkd-ApgUnKDJ9D6L0fTkLLojEw",
          text: "在朋友的介绍下，我才了解鹏飞课堂，最开始我以为这里是骗人的，直到体验了几节直播课程，我才发现是真的。后来，我跟老师沟通一下，老师火速帮忙报名，在经过一个月的系统学习下，我已经是设计高手了，虽然达不到顶级的水准，但是吊打同水平设计师不在话下，感谢鹏飞课堂！",
        },

      ], // 子盒子内容列表
      currentIndex: 0, // 当前显示的子盒子索引
      itemWidth: 116, // 子盒子宽度
      bannerList: [],
      iconSearch: "https://www.haixingkt.com/Public/Default/images/s-icon.png",
      currentCourse: "",
      headerRightTitle: ["快捷支付", "登录", "注册", "个人中心"],
      input: "",
      courseTitleList: [],
      courseList: [],

      traineeList: [
        {
          image:
            "https://www.haixingkt.com/Public/Uploads/20200813/5f34ff5d5ceb0200200.jpg",
          name: "小虎牙同学-UI作品展示",
          text: "UI设计小程序界面设计一款古典音乐的小程序，内部有H5界面，采用了插画风格，使整个页面更加有趣味性，贴合音乐主题。在柚子老师的指导下用了三天时间完成整个界面设计。",
        },
        {
          image:
            "https://www.haixingkt.com/Public/Uploads/20200813/5f34ff5d5ceb0200200.jpg",
          name: "洛洛同学-平面作品展示",
          text: "UI设计小程序界面设计一款古典音乐的小程序，内部有H5界面，采用了插画风格，使整个页面更加有趣味性，贴合音乐主题。在柚子老师的指导下用了三天时间完成整个界面设计。",
        },
        {
          image:
            "https://www.haixingkt.com/Public/Uploads/20200813/5f34ff5d5ceb0200200.jpg",
          name: "范范同学-影视后期作品展示",
          text: "UI设计小程序界面设计一款古典音乐的小程序，内部有H5界面，采用了插画风格，使整个页面更加有趣味性，贴合音乐主题。在柚子老师的指导下用了三天时间完成整个界面设计。",
        },
        {
          image:
            "https://www.haixingkt.com/Public/Uploads/20200813/5f34ff5d5ceb0200200.jpg",
          name: "伊莉雅同学-C4D作品展示",
          text: "UI设计小程序界面设计一款古典音乐的小程序，内部有H5界面，采用了插画风格，使整个页面更加有趣味性，贴合音乐主题。在柚子老师的指导下用了三天时间完成整个界面设计。",
        },
      ],
    };
  },
  created() {
    this.getBannerList();
    this.getCourseList();
    this.getRightInfo();
  },
  methods: {
    goPage(item){
      if(item.hasFree===0){
        this.$router.push({
          path: `/freeLive/${item.id}`,

        });
      }else{
        this.$router.push({ path: '/homeIndex', query: { id: item.id } })
      }

    },
    handleToDetail(id) {
      this.$router.push({path: "/article/detail", query: {id: id}})
    },
    scroll(direction) {
      if (direction === "left" && this.currentIndex > 0) {
        this.currentIndex--;
      } else if (
        direction === "right" &&
        this.currentIndex <= this.listprofile.length - 1
      ) {
        this.currentIndex++;
      }
    },
    goSchool(e) {
      if (e != 0) {
        this.$router
          .push({ path: "/schoolIndex", query: { name: "鹏飞课堂" } })
          .catch((err) => err);
      }
    },
    gasohol() {
      this.$router
        .push({ path: "/schoolIndex", query: { name: "鹏飞课堂" } })
        .catch((err) => err);
    },
    gohomeindex() {
      this.$router
        .push({ path: "/homeIndex", query: { name: "鹏飞课堂" } })
        .catch((err) => err);
    },
    rightGo(e) {
      if (e == 0) {
        this.$router
          .push({ path: "/payIndex", query: { name: "鹏飞课堂" } })
          .catch((err) => err);
      } else if (e == 1) {
        this.$router
          .push({ path: "/loginIndex", query: { name: "鹏飞课堂" } })
          .catch((err) => err);
      } else if (e == 2) {
        this.$router
          .push({ path: "/regIndex", query: { name: "鹏飞课堂" } })
          .catch((err) => err);
      } else if (e == 3) {
        this.$router
          .push({ path: "/userCenter", query: { name: "鹏飞课堂" } })
          .catch((err) => err);
      }
    },
    goLoginss(e) {
      this.$router
        .push({ path: "/loginIndex", query: { name: e } })
        .catch((err) => err);
    },

    async getBannerList() {
      const res = await getBannerApi({});
      this.bannerList = res.result.map((item) => item.image);
    },

    async getCourseList() {
      const res = await getCourseApi({});
      this.courseTitleList = res.result;
      this.currentCourse = this.courseTitleList[0].categoryName;
      this.courseList = this.courseTitleList[0].courseList;
    },

    async getRightInfo() {
      const res = await getRightInfo();
      this.rightInfo = JSON.parse(res.result);
    },

    handleCheckClick(id) {
      this.currentCourse = id;
      const cur = this.courseTitleList.find(item => item.categoryName == id)
      if(cur) {
        this.courseList= cur.courseList
      }
    },
  },
};
</script>
<style scoped lang="less">
.title {
  margin: 30px 0;
  position: relative;
  padding-left: 10px;
  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 4px;
    left: 0;
    width: 6px;
    height: 80%;
    border-radius: 2px;
    background: #007aff;
  }
}

.header-swiper {
  width: 100%;
  position: relative;
  .header-top {
    top: 30px;
    position: absolute;
    z-index: 99999;
    width: 100%;
    .header {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      line-height: 50px;
    }
  }
}

.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}

.heaerRightSearch /deep/.el-input-group__append {
  background: #007aff;
  color: #fff;
  border-color: #007aff;
}
.heaerRightSearch /deep/.el-input {
  border: none;
}

.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #007aff;
  border-radius: 50%;
  opacity: 1;
}

.headerRight {
  display: flex;
  align-content: center;
  text-align: center;
  justify-content: center;
}

.box {
  margin-right: 20px;
  font-size: 16px;
  transition: transform 0.1s ease-in-out 0.1s;
}

.box:first-child {
  font-size: 25px;
  cursor: pointer;
}

.box ~ .box:hover {
  cursor: pointer;
  transform: scale(1.3);
}

.no-border .el-input__inner {
  /* border: none; */
  background-color: transparent;
}

.searchStyle {
  width: 50px;
  height: 40px;
  border-radius: 0 5px 0 0;
  background-color: #1584f6;
  cursor: pointer;
  text-align: center;
}

.searchStyle-img {
  transform: scale(0.6);
}

.fills {
  margin-right: 30px;
}

.fills:hover {
  color: #ffffff;
  cursor: pointer;
}

.sipperImage:hover {
  cursor: pointer;
}
.sipperImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.concat {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .content-item {
    background-color: #fafafa;
    width: 100%;
    .item-box {
      width: 1200px;
      margin: auto;
    }
  }
  .content-item:nth-child(odd) {
    background-color: #f2f2f2 !important;
  }
}

.course-content {
  width: 100%;
  margin: auto;
  .category-filter {
    display: flex;
    margin: 10px 0px 20px;
    .item {
      height: 30px;
      line-height: 30px;
      padding: 0px 16px;
      border-radius: 15px;
      cursor: pointer;
      background: #fff;
      margin-right: 25px;
    }
    .is-active {
      background: #007aff;
      color: #fff;
    }
  }
  .course-block {
    display: flex;
    flex-wrap: wrap;

    .course-item {
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 20px;
      margin-bottom: 20px;
      box-sizing: border-box;
      overflow: hidden;
      width: 285px;
      &:nth-child(4n) {
        margin-right: 0px !important;
      }
      .course-top {
        width: 100%;
        height: 180px;
        position: relative;
        &::after {
          opacity: 0;
          content: "";
          position: absolute;
          display: flex;
          justify-content: center;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5)
            url("../assets/play_btn@2x.233e7a64.png") no-repeat;
          background-position-x: 50%;
          background-position-y: 50%;
          transition: all 0.5s;
        }
        img {
          width: 100%;
          height: 180px;
        }
        position: relative;
        .vip-mark {
          box-sizing: border-box;
          display: flex;
          z-index: 100;
          justify-content: center;
          align-items: center;
          right: 8px;
          top: 8px;
          position: absolute;
          width: 40px;
          height: 22px;
          color: #8e5323;
          font-size: 12px;
          font-style: normal;
          background: linear-gradient(170deg, #fbdec0, #f2b981);
          border-radius: 5px;
        }
      }
      &:hover {
        .course-top::after {
          opacity: 1;
        }
        .course-mid {
          color: #007aff;
        }
      }

      display: flex;
      flex-direction: column;
      transition: all 0.5s;
      position: relative;
      top: 0px;
      &:hover {
        top: -10px;
        box-shadow: 0 0 15px 0 #d9d9d9;
      }
      .course-mid {
        text-align: left;
        padding: 15px 24px 15px 18px;
        .limite-line-2 {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          word-break: break-all;
        }
      }

      .course-bot {
        border-top: 1px solid #f2f2f2;
        margin: 0 18px;
        display: flex;
        justify-content: space-between;
        padding: 14px 0;
        color: #999;
        font-size: 12px;
        .hasFree-box {
          color: green;
        }
      }
    }
  }
}

.capsule {
  border: 1px solid #ccc;
  height: 40px;
  line-height: 40px;
  text-align: center;
  box-shadow: 0 0 6px #ccc;
  border-radius: 20px;
}

.capsuleLeft {
  border-radius: 20px;
  background-color: #0787ec;
  width: 120px;
  height: 40px;
  text-align: center;
  color: #ffffff;
}

.sipperImage1 {
  transition: transform 0.3s ease;
}

.sipperImage1:hover {
  cursor: pointer;
  transform: translateY(-10px);
}

.courseTitles {
  position: relative;
  width: 190px;
  height: 178px;
  border-radius: 8px;
  margin-left: 35px;
  margin-bottom: 30px;
}

.bitteTittle {
  flex-wrap: wrap;
  margin-bottom: 30px;
  /* margin-left: 30px; */
}

.titleshadow {
  width: 100%;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  background: rgba(30, 33, 136, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 8px 8px;
  color: #ffffff;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
}

.leftarrow {
  z-index: 99999;
  position: absolute;
  left: 0;
  top: 110px;
}

.rightarrow {
  z-index: 99999;
  position: absolute;
  right: 0;
  top: 110px;
}

.content {
  overflow: hidden;
  position: relative;
}

.viewport {
  display: flex;
  overflow: hidden;
}

.slider {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.slide {
  width: 200px;
  height: 425px;
  padding: 0 15px;
  float: left;
  background: #fff;
  border-radius: 8px;
  margin-right: 35px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.buttontrainee {
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #99d4ff;
  border-radius: 20px;
  display: block;
  margin: 20px auto 30px;
  color: #fff;
  cursor: pointer;
}

.trainee {
  margin: 30px 0px;
}

.traineeBox {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
}

.dunno {
  width: 420px;
  text-align: left;
}

.fixed-sidebar {
  position: fixed;
  /* 将侧边栏固定在页面 */
  bottom: 40px;
  /* 距离页面底部的距离 */
  right: 20px;
  /* 距离页面右侧的距离 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #fff;
  width: 100px;
  z-index: 9999;

  /* 其他样式 */
}

.float_dav {
  text-align: center;
  z-index: 999999;
  padding: 20px 0;
  border-bottom: 1px solid #dadada;
}
</style>
