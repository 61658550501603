import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './common/request'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "../src/assets/css/variable.less"
import "../src/assets/css/public.less"
Vue.prototype.$http = http
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
