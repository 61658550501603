<template>
  <div class="header-top">
    <div class="header">
      <div class="left-box" style="color: #ffffff; font-size: 18px">
        <div
          v-for="(item, index) in headerTitle"
          :key="index"
          style="margin-right: 20px"
          class="box"
          @click="goSchool1(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="headerRight">
        <div class="flex heaerRightSearch">
          <el-input
            size="small"
            v-model="input"
            placeholder="搜索更多课程"
            class="no-border"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <div
          class="flex"
          style="margin-left: 40px; color: #dce9f8; font-size: 16px"
        >
          <div
            v-for="(item, index) in headerRightTitle"
            :key="index"
            class="fills"
            @click="rightGo(index)"
          >
            <span v-if="isShow(item)">{{ item }}</span>
          </div>
          <div v-if="account">
            <el-dropdown trigger="click" size="medium">
              <span class="el-dropdown-link account_user">
                <i class="el-icon-user-solid"></i>{{ account
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdown_exit">
                <el-dropdown-item @click.native="handleExit">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      iconSearch: "https://www.haixingkt.com/Public/Default/images/s-icon.png",
      headerTitle: ["首页", "所有课程"],
      headerRightTitle: ["快捷支付", "登录", "注册", "个人中心"],
      input: "",
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token") ? true : false;
    },
    ...mapGetters({ account: "account" }),
  },
  methods: {
    goSchool1(e) {
      if (e == 0) {
        this.$router.push("/");
      } else if (e == 1 || e == 2 || e == 3) {
        this.$router
          .push({ name: "schoolIndex", query: { periodId: "鹏飞课堂" } })
          .catch((err) => err);
      } else {
        location.reload();
      }
    },
    rightGo(e) {
      if (e == 0) {
        this.$router.push({ path: "/payIndex", query: { name: "鹏飞课堂" } });
        location.reload;
      } else if (e == 1) {
        this.$router
          .push({ path: "/loginIndex", query: { name: "鹏飞课堂" } })
          .catch((err) => err);
        location.reload;
      } else if (e == 2) {
        this.$router
          .push({ path: "/regIndex", query: { name: "鹏飞课堂" } })
          .catch((err) => err);
        location.reload;
      } else if (e == 3) {
        this.$router
          .push({ path: "/userCenter", query: { name: "鹏飞课堂" } })
          .catch((err) => err);
        location.reload;
      }
    },
    isShow(title) {
      if (title === "个人中心" && this.token) {
        return true;
      }
      if ((title === "登录" || title === "注册") && !this.token) {
        return true;
      }

      return false;
    },
    handleExit() {
      this.$store.commit("LAYOUT_USER")
      this.$router.push("/loginIndex")
    }
  },
};
</script>

<style scoped lang="less">
.header-top {
  width: 100%;
  background: #2b2b37;
  font-size: 16px;

  .header {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    .left-box {
      display: flex;
      align-items: center;
    }

    .heaerRightSearch /deep/.el-input-group__append {
      background: #007aff;
      color: #fff;
      border-color: #007aff;
    }
    .heaerRightSearch /deep/.el-input {
      border: none;
    }
  }
}
.account_user {
  cursor: pointer;
  color: rgb(220, 233, 248);
  font-size: 16px;
  i {
    padding-right: 5px;
    font-size: 18px;
  }
}

.dropdown_exit {
  background: #f7f8fa;
  width: 120px;
  border: #09090b;
}

.heaerRightSearch {
  text-align: center;
  line-height: 35px;
}

.headerRight {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-border .el-input__inner {
  border: none;
  background-color: transparent;
}

.searchStyle {
  width: 50px;
  height: 40px;
  border-radius: 0 5px 0 0;
  background-color: #1584f6;
  cursor: pointer;
  text-align: center;
}

.searchStyle-img {
  transform: scale(0.6);
}

.box {
  transition: transform 0.1s ease-in-out 0.1s;
}

.fills {
  margin-right: 30px;
}

.fills:hover {
  color: #ffffff;
  cursor: pointer;
}

.box {
  transition: transform 0.1s ease-in-out 0.1s;
}

.box:first-child {
  font-size: 25px;
  cursor: pointer;
}

.box ~ .box:hover {
  cursor: pointer;
  transform: scale(1.3);
}
</style>
