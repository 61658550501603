<template>
  <div class="myCourse">
    <div class="filter-box">
      <singleFilter
        v-model="filterData"
        ref="filter"
        size="small"
        :filter-list="filterList"
        @search="handleSearch"
      ></singleFilter>
    </div>
    <div
      class="content-box"
      v-infinite-scroll="load"
      infinite-scroll-disabled="disabled"
    >
      <div
        v-for="(item, index) in courseList"
        :key="index"
        class="content-item"
        @click="handleItemClick(item)"
      >
        <div class="header-top">
          <span>订单号：{{ item.orderNum }}</span>
          <span>开始/结束时间：{{ item.stAndEdTime }}</span>
        </div>
        <div class="order-box">
          <div class="left-box">
            <div class="cover-box">
              <img class="cover" :src="item.image" alt="" />
              <span v-if="item.hasFree === 1" class="vip-mark">VIP</span>
              <span v-if="item.liveStatus === 1" class="tag tag-living"
                ><i class="tag-icon"></i>直播中</span
              >
            </div>
          </div>
          <div class="right-box">
            <div class="top-box">
              <div class="info_name_overview">
                <div class="name-box">
                  {{ item.courseName }}
                </div>
                <div class="overview-box">
                  {{ item.overview }}
                </div>
              </div>
              <div class="topic-box" v-if="item.adCourseChapters">
                <div
                  class="item"
                  v-for="(chapter, index) in item.adCourseChapters"
                  :title="chapter.name"
                  :key="chapter.id"
                >
                  {{ index + 1 }}. {{ chapter.name }}
                </div>
              </div>
            </div>
            <div class="bottom-box">
              <div class="tag-box">
                <span>主讲：{{ item.teacherName }}</span>
                <span
                  ><el-tag size="small" type="warning">{{
                    getByIdFindName(item.hasLive, hasLiveList)
                  }}</el-tag></span
                >
                <span
                  ><el-tag size="small" type="danger">{{
                    statusDic[item.status]
                  }}</el-tag></span
                >
              </div>

              <div>
                <el-button
                  @click="handleDetail(item)"
                  size="mini"
                  type="success"
                  >立即学习</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-info" v-if="loading">加载中...</div>
      <div class="bottom-info" v-if="noMore">没有更多了</div>
    </div>
    <submitWork ref="work"></submitWork>
  </div>
</template>

<script>
import { getCategoryApi } from "@/common/api/schoollDetails";
import { getUsercourseListApi } from "@/common/api/user";
import singleFilter from "@/components/singleFilter.vue";
import submitWork from "@/components/submitWork.vue";
export default {
  components: {
    singleFilter,
    submitWork,
  },
  data() {
    return {
      statusDic: {
        0: "未学习",
        1: "已学习",
        2: "已过期",
      },
      filterData: {
        hasFree: "",
        categoryId: "",
        hasLive: "",
      },
      filterList: [
        {
          label: "课程分类",
          key: "hasFree",
          value: "",
          options: [
            {
              id: 0,
              name: "公开课",
            },
            {
              id: 1,
              name: "会员课",
            },
          ],
        },
        {
          label: "课程类型",
          key: "categoryId",
          value: "",
          options: [],
        },
        {
          label: "观看类型",
          value: "",
          key: "hasLive",
          options: [
            {
              id: 0,
              name: "直播",
            },
            {
              id: 1,
              name: "录播",
            },
          ],
        },
      ],
      hasFreeList: [
        {
          id: 0,
          name: "免费",
        },
        {
          id: 1,
          name: "收费",
        },
      ],
      hasLiveList: [
        {
          id: 0,
          name: "直播",
        },
        {
          id: 1,
          name: "录播",
        },
      ],
      courseList: [],
      pageNo: 1,
      pageSize: 3,
      total: 0,
      loading: false,
    };
  },
  created() {
    this.getCategoryList();
    this.getCourseList();
  },
  computed: {
    noMore() {
      return this.courseList.length >= this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    async getCategoryList() {
      const res = await getCategoryApi();
      this.filterList[1].options = res.result;
    },
    handleLive(item) {
      this.$router.push({
        path: `/live/${item.id}`,
        query: { orderNum: item.orderNum },
      });
    },
    async getCourseList(load) {
      this.loading = true;
      const res = await getUsercourseListApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        ...this.filterData,
      });
      if (res) {
        if (load) {
          this.courseList = [...this.courseList, ...res.result];
        } else {
          this.courseList = res.result;
        }
      }

      // this.total = res.result.total;
      this.loading = false;
    },
    handleSubmitWork(item) {
      this.$refs.work.edit(item);
    },
    handleSearch() {
      this.pageNo = 1;
      this.courseList = [];
      this.getCourseList();
    },

    getByIdFindName(id, list) {
      const item = list.find((item) => item.id == id);
      return item && item.name ? item.name : "";
    },

    load() {
      this.pageNo = this.pageNo + 1;
      this.getCourseList(true);
    },
    handleItemClick(item) {
      console.log(item, "item");
    },
    handleDetail(item) {
      // this.$router.push({path: "/homeIndex", query: {
      //   id: item.courseId
      // }})
      this.$router.push({
        path: `/live/${item.courseId}`,
        query: { orderNum: item.orderNum },
      });
    },
  },
};
</script>

<style scoped lang="less">
.filter-box {
}
.tag-living {
  background-color: rgba(255, 120, 0, 0.8);
  position: absolute;
  z-index: 10;
  top: 4px;
  left: 4px;
  display: block;
  width: 61px;
  height: 19px;
  line-height: 19px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  .tag-icon {
    border-radius: 50%;
    background-image: url(https://a.msstatic.com/huya/main3/components/SubscribeList/Card/img/icon_live_eb974.png);
    animation: matchLiving 0.6s steps(6) infinite;
    margin-left: -4px;
    margin-right: 3px;
    padding: 0 8px;
  }
}
@keyframes matchLiving {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -109px 0;
  }
}
.myCourse {
  width: 100%;
  // padding: 20px;
  padding-top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .vip-mark {
    box-sizing: border-box;
    display: flex;
    z-index: 100;
    justify-content: center;
    align-items: center;
    right: 4px;
    top: 4px;
    position: absolute;
    width: 30px;
    height: 16px;
    color: #8e5323;
    font-size: 10px;
    font-style: normal;
    background: linear-gradient(170deg, #fbdec0, #f2b981);
    border-radius: 5px;
  }
}

.content-box {
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
  flex: 1;
  margin: auto;
  display: flex;
  flex-direction: column;

  .content-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background: #fff;
    box-sizing: border-box;
    // justify-content: space-between;
    cursor: pointer;
    width: 100%;
    padding: 0px 20px 20px;
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid #eaeaea;
    &:hover {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      .title {
        color: var(--color-main);
      }
      .study-btn {
        color: #fff !important;
        background: var(--color-main);
      }
      .cover {
        transform: scale(1.1);
      }
    }
    .header-top {
      color: #999;
      padding: 15px 0px;
      font-size: 12px;
      span {
        padding-right: 20px;
      }
    }
    .order-box {
      display: flex;
      justify-content: space-between;

      .right-box {
        flex: 1;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top-box {
          display: flex;
          justify-content: space-between;
          height: 120px;
          .info_name_overview {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .name-box {
              color: #333;
              font-size: 20px;
              font-weight: 700;
            }
            .overview-box {
              padding-top: 10px;
              color: #666;
              font-size: 14px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              word-wrap: break-word;
              word-break: break-all;
            }
          }
          .topic-box {
            height: 80%;
            overflow: auto;
            box-sizing: border-box;
            .item {
              width: 150px;
              padding-bottom: 5px;
              color: #666;
              font-size: 14px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        .bottom-box {
          color: #999;
          font-size: 12px;
          .tag-box {
            > span {
              padding-right: 20px;
            }
          }

          display: flex;
          justify-content: space-between;
        }
      }
      .left-box {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 185px;

        // height: 250px;
        .cover-box {
          width: 185px;
          height: 120px;
          overflow: hidden;
          border-radius: 10px;
          .cover {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            transition: all 0.5s;
          }
        }
        // &::after {
        //   content: "";
        //   display: block;
        //   margin: 0 16px;
        //   height: 22px;
        //   background: url("@/assets/shadow_1380@2x.d42d9897.png") no-repeat;
        //   background-size: 100%;
        // }
      }
    }
  }

  .bottom-info {
    width: 100%;
    text-align: center;
    margin: 20px 0px;
  }
}
</style>
