<template>
  <div>
    <headerWorld></headerWorld>
    <el-dialog
      title="查看用户注册协议"
      :visible.sync="dialogVisible1"
      width="60%"
    >
      <span
        ><p>
          欢迎使用本平台，本平台是湖北鹏飞信息技术有限公司（以下简称“公司”或“本平台”）合法拥有并运营的。标注名称为“本平台”的客户端应用程序以及相关网站。
          在您（网络用户，以下简称“用户”）开始使用本平台之前，请您仔细阅读《用户服务协议》(以下简称"本协议”)，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用收费网络服务的条款、法律适用和争议解决条款等重要内容，本协议将以加粗形式提示您注意，您应重点阅读。如您未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用本平台提供的基于互联网和移动网的相关服务（以下称"网络服务”）。一旦用户完成注册和使用本平台，即表示用户与本平台已达成协议，接受本服务协议全部条款，并承诺作为本协议的一方当事人接受协议的约束。如对本协议内容有任何疑问、意见或建议，您可通过登录本平台，通过“建议与投诉”页面提交信息，与本平台联系。本说明亦属本协议的一部分。
          第一条、协议条款的确认和接授
        </p>
        <p>
          1.1湖北海星信息技术有限公司同意按照中国境内互联网法律、法规之规定、本协议条款约定及本网站不定时发布的操作规则提供基于互联网和移动网的相关服务(以下称（“网络服务”)。为获得网络服务，申请人应当认真阅读、充分理解本协议中各条款，包括免除或者限制本网站责任的免责条款及对用户的权利限制条款。认真阅读并选择接受或不接受本协议(未成年人应在法定监护人陪同下阅读)。同意接受本协议的全部条款的，申请人应当按照页面上的提示完成全部的注册程序，并在注册程序过程中点击”同意”按钮，否则视为不接受本协议全部条款，申请人应当终止并退出申请。
        </p>
        <p>
          1.2
          湖北海星信息技术有限公司有权依运营的需要单方决定，安排或指定其关联公司、控制公司、继承公司或湖北海星信息技术有限公司认可的第三方公司运营“本平台”
          网站及APP。
          并且就本协议项下涉及的某些服务，可能会由湖北海星信息技术有限公司的关联公司、控制公司、继承公司或湖北海星信息技术有限公司认可的第三方公司向您提供。您知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。
        </p>
        <p>
          1.3湖北海星信息技术有限公司有权根据实际运营需要而不定时修改本协议或补充协议，如本协议有任何变更，将通过网站消息或其他方式通知用户。如用户不同意相关变更，则应立即终止账号使用，否则即视同用户同意并完全接受修订后的协议版本。经修订的协议一经公布于本平台链接及页面，立即自动生效，亦成为本协议的一部分。注册用户登录且继续使用服务将被视为已接受修订后的协议。除书面另行声明外，任何扩大的服务范围及新增提供的内容均受本协议约束。当发生争议时，应以最新用户协议的内容为准。
        </p>
        <p>
          1.4
          由于湖北海星信息技术有限公司提供的产品、服务及活动比较复杂，您与湖北海星信息技术有限公司签署的本协议列明的条款并不能完整罗列并覆盖您与本平台所有权利与义务，现有的约定也不能保证完全符合未来发展的需求，为了以更清楚明确的方式保障您的知情权，本平台会针对特定产品与服务、特定用户、特定法益保护规则以协议、声明、规则、政策等形式公示和征求您的同意，如您在我们平台上使用第三方提供的产品及/或服务时，除遵守本协议约定外，还应遵守第三方的服务条款。
          相关声明及政策、本平台规则和协议均为本协议的补充协议，与本协议不可分割且具有同等法律效力。特别是我们提醒您重点注意以下政策、特定协议、声明，您应在充分阅读并同意特定用户产品与服务的全部内容后再使用该特定产品/服务或从事与涉及下述协议规范范围的事项：
          《隐私政策》：关于本平台个人信息处理和保护规则、用户对个人信息的控制权的约定；
          《版权与政策指引》：关于本平台提供内容来源的声明；
          《收费网络服务协议》：有关本平台使用帮助、投诉举报、退款申请、变更申请等规则；
        </p>
        <p>第二条、用户账号</p>
        <p>
          2.1用户应通过本平台为用户提供的合法通道进行注册。用户有权选择合法的字符组合作为自己的账号，并自行设置符合安全要求的密码。注册成功后，用户设置的账号、密码、初始信息是用户使用本平台及相关网络服务的凭证。
        </p>
        <p>
          2.3基于网络服务的特殊性和国家相关法律法规，用户在使用部分服务时或网站、软件升级时可能被要求提供真实姓名、手机号码、联系邮箱等资料，在上述信息发生变动时，用户应及时更新注册资料。如用户提供的资料不合法、不真实、不完整的，用户可能无法使用某些服务。同时，本平台保留因此拒绝或结束用户使用本平台及相关网络服务资格的权利。
        </p>
        <p>
          2.4用户在本平台中的账号所有权及有关权益均归本平台所有，用户完成注册手续后仅享有该账号的使用权。本平台禁止用户以任何形式赠与借用、出租、转让、售卖或以其他方式许可他人使用该账号。如果本平台发现或者有合理理由认为使用者并非账号初始注册人，有权在未通知您的情况下，暂停或终止向该账号提供服务，并有权注销该账号，而无需向注册该账号的用户承担法律责任。因账号归属产生的用户间的纠纷，本平台概不负责。本平台保留追究用户在上述情况下法律责任的权利。
        </p>
        <p>
          2.5用户注册账号以及利用该账号所进行的一切活动视为本人的真实意思表示和本人的行为。用户有责任维护个人账号、密码的安全性与保密性，并对以该账号名义所从事的活动承担全部法律责任，包括但不限于在本平台平台上进行的任何数据修改、言论发表、
          款项支付等操作行为。如用户发现任何非法使用用户账号的情况，应立即修改账号密码并妥善保管。因黑客行为或用户的保管疏忽导致账号非法使用，本平台不承担任何责任。
        </p>
        <p>
          2.6用户应当使用本人名下合法的金融账户(包括银行卡、支付宝、微信、分期产品等)为用户账户充值或行使付款行为。若用户存在使用第三人的金融账号进行充值或付款，或委托第三人代为充值或付款之行为的，则以上行为被视作本人的行为；若由于该第三人行为导致充值或付款行为失败或成功后又被撤销的，均被认为是用户本人真实意思的表示和用户本人实施的行为，由此所造成的一切法律后果均由用户自行承担。因第三人付款产生的任何非本平台责任的纠纷，本平台概不负责。
        </p>
        <p>
          2.7为了充分使用账号资源，本平台有权对注册后未及时进行初次登录使用、非付费连续90日未登录闲置账号或付费有效期期满后且连续180日未登录闲置账号进行收回处理。如账号被收回，用户将可能无法通过此前持有的账号登录并使用本平台及相关网络服务，该账号下保存的任何个性化设置和使用记录将无法恢复。在收回账号之前，本平台将以适当的方式向用户作出提示，如用户未在相关提示后一定期限内登录、使用账号，本平台将进行账号收回。
        </p>
        <p>
          2.8在需要终止使用本平台账号服务时，符合以下条件的，您可以申请注销您的本平台账号：(1)您仅能申请注销您本人的账号，并依照本平台指示的流程进行注销；
          (2)您仍应对您在注销账号前且使用本平台期间的行为承担相应责任;；(3)注销成功后，账号记录、功能等将无法恢复或提供；(4)注销成功后，本协议自动终止。如您需要注销您的本平台账号，请联系本平台官方客服，按提示进行注销。
        </p>
        <p>
          2.9在丢失或遗忘密码后，用户可联系本平台官方客服及时申诉请求找回账号或密码。用户理解并认可，本平台的密码找回机制仅需要识别申诉单上所填资料与系统记录资料具有一致性，而无法识别申诉人是否系真正账号有权使用者。
          第三条、服务内容
        </p>
        <p>
          3.1
          本平台利用现有技术上维护整个平台的正常运行，为用户提供免费或者收费的网络服务，具体内容根据平台相应页面公示而定（比如免费公开课、VIP课程、网络社区服务等)。对用户在使用本平台中所遇到的与交易或注册有关的问题及反映的情况，本平台会及时作出回复。
        </p>
        <p>
          3.2
          本平台的所有服务均附期限。用户应在截止日期前享受其购买的服务，因到期服务终止所导致的任何后果，本平台不承担任何责任。
        </p>
        <p>
          3.3对于收费的网络服务，用户需要支付相应费用。本平台会在用户使用之前给予用户明确的提示，只有用户根据提示确认并支付相关费用，用户才能使用该等收费网络服务。如用户未支付相关费用，则本网站有权不向用户提供该等收费网络服务。收费网络服务同时适用《收费网络服务协议》约定，户自购买之日起24小时之内且未产生听课记录(不含试听)的，可无理由退班。
        </p>
        <p>
          3.4
          本平台将为付费用户通过各终端平台（包含但不限于PC端和APP、微信公众号）提供授课服务，服务时间依据本平台授课安排，具体授课服务内容包含：直播观看服务、直播回放观看服务、直播间内答疑服务、录播观看服务，提供的学习服务内容包含：学管师导学服务、在线学习问题答疑服务、作业点评服务。
        </p>
        <p>
          3.5用户通过本人在本平台的注册账号享受相应服务。用户应自行配备上网的所需设备，包括个人电脑、调制解调器或其他必备上网装置；用户应自行负担因使用这种接入方式而产生的上网电话费、上网信息费等费用。
        </p>
        3.6
        用户可以通过本平台提供的教学服务，提升相关技能，但是否能够实现在相关技能领域全职或兼职就业的结果，取决于用户的学习效果，就业也需要以综合素质为前提。本平台仅在官网展示相关合作的就业渠道，但不承诺为用户提供相关全职及兼职的就业岗位，更不会承诺全职及兼职的就业岗位薪酬。
        <p>
          3.7
          本平台所有课程服务均为有固定期限的服务，每一课程均拥有独立计算的课程服务期。本平台仅在课程服务期内提供课程和课程服务，并有权拒绝对任何已经超过课程服务期的所购课程提供任何形式的课程内容或课程服务。课程服务期自课程服务开通后立即起算，不因用户的自身原因延长或中断，请用户务必合理安排用户的学习时间。对任何课程的请假、缺勤等情况都不能延长或中断服务期的计算。课程服务期届满后，课程服务自动关闭。
          第四条、使用规则
        </p>
        <p>
          4.1用户在使用本平台网络服务过程中，必须遵循国家的相关法律法规，同时遵循以下规则：(1)不得为任何非法目的而使用本平台；
          (2)不得以任何形式使用本平台台侵犯本平台的商业利益，包括并不限于发布非经本平台许可的商业广告；(3)不得利用本平台进行任何可能对互联网或移动网正常运转造成不利影响的行为；(4)不得利用本平台提供的网络服务，上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；(5)不得大量发布重复帖、广告帖、恶意灌水或刷屏；(6)不得利用本平台进行任何不利于本平台的行为。
        </p>
        <p>
          4.2凡用户昵称中出现如下信息者(包括但不限于)，本平台有权进行屏蔽、
          锁定，甚至注销：(1)使用严重违反国家法律法律及风序良俗，包含或暗藏反动、色情等信息(含符号、地址等)的昵称；(2)使用党和国家领导人、古今中外比较有影响的名人网络上知名人士的真实姓名、字号艺名、笔名、头衔等为昵称；(3)使用国家机构、企事业单位、知名公司或品牌的名称及标识为昵称；(4)含性别歧视、种族歧视、地域歧视等信息的昵称；(5)含自侮辱、猥亵或者对他人进行侮辱、挑衅、漫骂、人身攻击等不文明、不健康信息的昵称；(6)含易产生歧义、引起他人误解的信息的昵称；(7)未获得本平台网官方正式许可而使用带有本平台相关名称的昵称；(8)与本平台老师、及社区管理人员的昵称近似或雷同，并可能引起混淆或纠纷的；(9)带有明显广告性质的昵称；(10)带有其它法律法规以及社区规则中所禁止信息的昵称。
        </p>
        <p>
          4.3
          本平台可依其合理判断，对违反有关法律法规或本协议约定、或侵犯、妨害、威胁任何人权利或安全的内容，或者假冒他人的行为，有权停止传输任何前述内容，并有权依其自行判断对违反本条款的任何用户采取适当的法律行动，包括但不限于，删除具有违法性、侵权性、不当性等内容，阻止其使用本平台全部或部分网络服务，并且依据法律法规保存有关信息并向有关部门报告等。任何不当用户行为引起的本平台认为应当终止服务的情况，本平台有权随时处理，而无须征得用户的同意。
        </p>
        <p>
          4.4对于经由本平台而传送的内容，本平台不保证前述内容的正确性、完整性或品质。用户在接受本服务时，有可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，本平台均不对任何内容负责，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。本平台有权(但无义务)自行拒绝或删除经由本平台网络服务提供的任何内容。用户使用上述内容，应自行承担风险。
        </p>
        <p>
          4.5用户通过本平台网络服务所发布的任何内容并不反映本平台的观点或政策，本平台对此不承担任何责任。用户须对上述内容的真实性、合法性、无害性、有效性等全权负责，与用户所发布信息相关的任何法律责任由用户自行承担，与本平台无关。
        </p>
        <p>第五条、知识产权</p>
        <p>
          5.1
          本平台中提供的内容(包括但不限于软件、技术、程序、网页、字图片图像音频、视频、图表、版面设计、电子文档等)的知识产权属于本平台所有。本平台提供本服务时所依托的软件的著作权、专利权及其他知识产权均归本平台所有。未经本平台许可，任何人不得擅自使用(包括但不限于通过任何机器人程序或设备监视、复制、传播、展示、镜像、上载、下载本平台软件及相关服务中的内容)或从事其他任何违反著作权法等相关法律、法规的活动。对侵犯本平台知识产权的个人和组织，本平台和作品的著作权人将依法追究其民事及/或刑事责任。
        </p>
        <p>
          5.2用户确认在使用本平台及相关服务时发布上传的文字、图片、视频、音频等均由您原创或已获合法授权(含转授权)。用户通过本平台上传、发布的任何内容的知识产权归属用户或原始著作权人所有。
        </p>
        <p>
          5.3用户理解并同意对上述5.2条用户上传的内容，授予本平台及其关联公司、继承公司全球范围内、免费、非独家、可再许可(通过多层次)的权利(包括但不限于复制权、翻译权、汇编权、信息网络传播权、改编权、制作衍生品等)，使用范围包括但不限于在当前或其他网站、应用程序、产品或终端设备等。用户在此确认并同意上述权利的授予包括本平台和/或本平台品牌有关的任何推广、广告、营销和/或宣传中使用和以其他方式开发内容(全部或部分)的权利和许可。为避免疑惑，用户同意，上述权利的授予包括使用、复制和展示用户拥有或被许可使用并植入内容中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识和公司标记(如有)以及任何其他品牌、营销或推广资产的权利和许可。
        </p>
        <p>
          5.4用户确认并同意授权本平台以自己的名义或委托专业第三方对侵犯用户上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，本平台有权对维权事宜做出决策并独立实施。
        </p>
        <p>
          5.5用户理解并同意用户通过本平台了解并自愿自主参与的本平台或者发布在本平台上的第三方组织、举办的线上、线下活动中，本平台可以进行拍照、摄像、录音、录像等记录活动。本平台对上述记录活动过程中所产生的作品、录音录像作品等单独地享有全部、完整的知识产权。用户以此授予本平台及其关联公司独家的、全球通用的、永久的、免费的许可使用权利(并有权在多个层面对该权利进行再授权)，使本平台及其关联公司有权(全部或部分地)使用、复制、修订、改写发布、翻译、分发、执行和展示用户的全部资料数据(包括但不限于注册资料、交易行为数据及全部展示于网站的各类信息)或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内；用户同意，上述权利的授予包括使用、复制和展示用户拥有或被许可使用并植入内容中的个人形象、肖像、姓名、声音、个人经历等。
        </p>
        <p>
          5.6用户在任何情况下都不要私自使用本平台的包括但不限于本平台在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等(以吓统称为“标识”)。
          未经本平台事先书面同意，用户不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于用户违反本协议使用本平台上述商标、标识等给本平台或他人造成损失的，由您承担全部法律责任。
        </p>
        <p>
          5.7
          本平台为湖北海星信息技术有限公司及其相关服务的开发、运营提供技术支持,并对本平台平台及其相关服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。
        </p>
        <p>
          5.8如您发现本平台平台网站、APP内存在任何侵犯您权利的内容，可以根据本平台《版权政策和侵权投诉指引》中的指示通知本平台，提供您有相关权利的初步证据，本平台将会根据当地法律规定及时处理您的投诉。
          第六条、服务的中断和终止终止部分或全部的网络服务(包括收费网络服务)。
        </p>
        <p>
          6.1鉴于网络服务的特殊性，用户同意本平台有权随时变更、中断或知用户，也无需对任何用户或任何第三方承担任何责任；(1)如变更、中断或终止的网络服务属于免费网络服务，本平台无需通知用户，也无需对任何用户或任何第三方承担任何责任；(2)如变更、中断或终止的网络服务属于收费网络服务，本平台应当在变更、中断或终止之前事先通知用户，并应向受影响的用户提供等值的替代性的收费网络服务；(3)中断服务情况下，如用户不愿意接受替代性的收费网络服务，就该用户已经向本平台支付的服务费，本平台应当按中断服务天数/收费协议服务天数比例退还相应服务费给该用户。本平台不对因此产生的第三方或者间接用户已经向本平台支付的服务费，本平台应当按照该用户实际使用相应收费网络服务的情况扣除相应服务费之后将剩余的服务费退还给该用户，本协议终止。(4)终止服务情况下，如用户不愿意接受替代性的收费网络服务，本平台不对因此产生的第三方或者间接损失承担任何责任。
        </p>
        <p>
          6.2用户理解，本平台需要定期或不定期地对提供网络服务的平台(如互联网网站、移动网络等)或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，本平台无需为此承担任何责任，但本平台应尽可能事先进行通告。
        </p>
        <p>
          6.3如发生下列任何一种情形，本平台有权随时中断或终止向用户提供本协议项下的网络服务(包括收费网络服务)而无需对用户或任何第三方承担任何责任:
          (1)用户提供的个人资料不真实；(2)用户违反本协议中规定的使用规则；(3)用户在使用收费网络服务时未按规定向本平台支付相应的服务费。
        </p>
        <p>
          6.4如用户注册的免费网络服务的账号在任何连续90日内未实际使用，或者户注册的收费网络服务的账号在其订购的收费网络服务的服务期满之后连续180日内未实际使用，则本平台有权删除该账号、删除该用户的所有个人信息并停止为该用户提供相关的网络服务。
          第七条、免责条款
        </p>
        <p>
          7.1用户明确同意其使用本平台网络服务所存在的风险将完全由其自己承担；因其使用本平台网络服务而产生的一切后果也由其自己承担，本平台对用户不承担任何责任。
        </p>
        <p>
          7.2
          本平台不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。因本平台的过错造成服务中断或停止，本平台应当及时处理，但对用户因此所遭受的本平台在本协议生效时无法预见的间接损失、预期利益损失等，本平台不承担责任。
        </p>
        <p>
          7.3
          本平台不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由本平台实际控制的任何网页上的内容，本平台不承担任何责任。
        </p>
        <p>
          7.4用户在本平台支付时选择的第三方支付方式，在支付过程中因用户自身原因或第三方支付平台原因造成的异常使其账户无法正常使用或遭受损失，本平台对此概不负责，一切损失由用户自行承担。
          本平台用户(或网友)所上传的相片、文章或讨论区发言内容，由该用户(或网友)自行对该相片文章或讨论区发言内容担保其知识性、专业性以及台法性。
        </p>
        <p>
          7.6用户同意，对于本平台向用户提供的下列产品或者服务的质量缺陷本身及其发的任何损失，本平台无需承担任何责任：(1)本平台向用户免费提供的各项网络服务；
          (2)本平台向用户赠送的任何产品或者服务；(3)本平台向收费网络服务用户附赠的各种产品或者服务；(4)本平台社区及用户上传的相关信息为建议性内容，仅供参考，不做指导性意见。
        </p>
        <p>
          7.7对于因不可抗力或本平台不能控制的原因造成的网络服务中断或其它缺陷，本平台不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
        </p>
        <p>
          7.8
          本平台不就由在本平台上产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向用户承担或任何第三负责。
          第八条、隐私声明
        </p>
        <p>
          8.1
          本平台非常重视用户信息的保护，保护用户个人信息是本平台的基本原则之一。本平台与用户一同致力于用户个人信息(即能够独立或与其他信息结合后识别用户身份的信息)的保护。
        </p>
        <p>
          8.2用户同意：本平台或其关联公司在必要时有权根据用户注册时或接受服务时所提供的联系信息(包括但不限于电子邮件地址、联系电话、联系地址、即时聊天工具账号等)，通过电
          子邮件、电话、短信、邮寄、即时聊天、弹出页面等方式向用户发送如下信息:
          (1 )各类重要通知信息，可能包括但不限于订单、交易单、
          修改密码提示等重要信息。此类信息可能对用户的权利义务产生重大的有利或不利影响，用户务必及时关注。(2)商品和服务广告、促销优惠等商业性信息。若用户不愿意接收此类信息，则可通过告知(书面)的方式通知本平台或其关联公司取消发送，亦可通过本平台或其关联公司所提供的相应退订功能(若有)进行退订。
        </p>
        <p>
          8.3为了为用户提供更好的服务和相应的技术支持，用户同意本平台可将用户注册资料及使用信息提供本平台及其关联公司使用。您理解并同意，本平台有权在遵守法律法规、本协议以及本平台《隐私政策》的前提下，收集、使用(合商业合作使用)、存储和分享您的个人信息，同时，本平台将依法保护您浏览、修改、删除相关个人信息以及撤回同意的权利。
        </p>
        <p>
          8.4用户可随时浏览、修改自己提交的个人身份信息，用户理解并同意出于安全性和身份识别(如账号或密码找回申诉服务等)的考虑，用户可能无法修改注册时提供的初始注册信息及其他验证信息。
        </p>
        <p>
          8.5请用户注意勿在使用本平台及相关服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由您自行承担。
        </p>
        <p>
          8.6本平台将运用与本平台及相关服务相匹配的安全技术及其他安全措施，并建立完善的管理制度来保护您的个人信息，防止用户的信息被不当使用或被未经授权的访问、使用或泄漏。
        </p>
        <p>
          8.7未经用户的同意，本平台不会向本平台以外的任何公司、组织和个人披露您的个人信息，下列情形除外：(1)事先获得用户的明确授权同意；(2)用户自行向其他公司、组织和个人共享、转让、公开的；(3)与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；(4)根据适用的法律法规的要求、强制性的行政和司法要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的。在符合法律法规的前提下，当本平台收到前述披露信息的请求时，本平台会要求对方必须出具与之相应的法律文件，如传票或调查函。本平台将对所有的请求都进行了审慎的审查，以确保其具备合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；(5)在法律法规允许的范围内，为维护本平台其他用户、公司及其关联公司、控制公司的生命、财产等合法权益或维权产品或服务的安全稳定运行所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等(不包括违反本协议中所做的承诺而为获利目的对外公开或提供个人信息)；(6)从台法公开披露的信息中获得的个人信息，如合法的新闻报道、政府信息公开等渠道；(7)法律法规规定的其他情形。
        </p>
        <p>
          8.8考虑到本平台及相关服务中可能包括或链接至第三方提供的信息或其他服务(包括网站)。用户知悉并理解，运营该等服务的第三方可能会要求用户提供个人信息。本平台特别提醒用户，需要认真阅读该等第三方的用户协议、隐私政策以及其他相关的条款，妥善保护自己的个人信息，仅在必要的情况下向该等第三方提供。本协议（以及其他与本平台服务相关的协议和规则(包括但不限于本平台《隐私政策》)不适用于任何第三方提供的服务，本平台对任何因第三方使用由用户提供的个人信息所可能引起的后果不承担任何法律责任。更多关于用户个人信息保护的内容，请参看本平台《隐私政策》。
          第九条、违约处理
        </p>
        <p>
          9.1针对用户违反本协议或其他服务条款的行为，本平台有权独立判断并视情况采取预先警示拒绝发布、立即停止传输信息删除跟帖、音频、视频等内容、短期禁止发言、限制账号部分或者全部功能直至永久关闭账号等措施。本平台有权公告处理结果，且有权根据实际情况决定是否恢复使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。对已删除内容本平台有权不予返还。
        </p>
        <p>
          9.2因用户违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，用户应当自行承担全部法律责任。因用户的违法或违约行为导致本平台向任何第三方赔偿或遭受国家机关处罚的，用户还应足额赔偿本平台因此遭受的全部损失。
        </p>
        <p>
          9.3如因本平台违反有关法律、法规或本协议项下的任何条款而给用户造成损失，本平台同意承担由此造成的直接损害赔偿责任。
        </p>
        <p>
          9.4
          本平台尊重并保护法人、公民的知识产权、名营权、姓名权、隐私权等合法权益。用户保证，在使用本平台及相关服务时，上传的文字、视频、音频、链接等不侵犯任何第三方的知识产权、名营权、姓名权、隐私权等权利及合法权益。否则，本平台有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，用户应自行承担全部法律责任；如因用户的侵权行为导致本平台堂遭受损失的(包括但不限于经济、商营等损失)，用户还应足额赔偿本平台遭受的全部损失。
          第十条、法律与管辖
        </p>
        <p>
          10.1本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。倘若本协议之任何规定因与中华人民共和国大陆地区的法律抵触而无效，则这些条款将尽可能接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。
        </p>
        <p>
          10.2本协议的签署地点为中华人民共和国湖北省孝感市孝南区，若您与本平台发生争议的，双方应尽量友好协商解决，协商不成，您同意应将争议提交至湖北省孝感市孝南区民法院管辖。
        </p>
        <p>
          10.3本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。
        </p>
        <p>
          10.4用户和本平台均是独立的主体，在任何情况下本协议不构成本平台对用户的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。
        </p>
        <p>
          10.5本协议的版权为本平台所有，本平台保留一切解释和修改的权利。 （完）
        </p>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible1 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="查看用户隐私协议"
      :visible.sync="dialogVisible2"
      width="60%"
    >
      <span
        >本用户协议双方为湖北鹏飞信息技术有限公司与本平台网络用户（以下简称“用户”），本用户协议具有合同效力。
        本平台指的是湖北海星信息技术有限公司旗下互联网教育平台及其关联公司（以下简称“公司”或“本平台”）。
        本平台将保护用户隐私作为一项基本政策。您在使用本平台的产品与/或服务时，本平台可能会收集和使用您的相关信息。本隐私政策将向您说明本平台如何收集、使用、保存、共享和转让这些信息。
        如您正在使用本平台的关联公司、合作伙伴/机构之产品或服务的，则本政策同样适用于该部分产品或服务。
        本隐私政策与您在使用本平台提供的服务以及该服务所包括的各种功能（以下简称为本平台的产品与/或服务）关系紧密，请您在使用本平台的产品与/或服务前仔细阅读并完整理解本隐私政策的全部内容，并做出您认为适当的选择。本隐私政策可能涉及的相关术语，本平台会尽量采用通俗易懂的表述方式，以便您可以更好的理解。您使用或在本平台更新本隐私政策后继续使用本平台的产品与/或服务，即表示您已同意本隐私政策的内容，并同意本平台按照本隐私政策收集、使用、保存和共享您的相关信息。
        本平台深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。本平台致力于维持您对本平台的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，本平台承诺，本平台将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
        一、本平台如何收集和使用个人信息 （一）来自您的主动授权
        您可能会在某些场景中，主动向本平台提供个人信息以获得更加完整的功能体验，本平台会在相应的功能中提供信息提交的入口。若您不提供这类信息，您依然可以正常使用本平台的相关服务，但部分附加功能可能无法使用。例如：
        (1)
        基于地理位置信息的个性化功能。本平台会收集您的地理位置信息，来判断您所在的地点，自动为您进行网络环境优化、好友同桌推荐、区域排行查看等服务。
        (2)
        基于通讯录信息的附加功能。本平台的某些功能可能会建议或要求您将通讯录提供，以为您进行好友匹配等社交关系服务。
        (3)
        以及其他一些本平台的应用程序或网页显示向您征询许可授权收集、使用个人信息的附加功能。
        上述功能可能需要您在您的设备中向本平台的网站、应用程序开启相应的访问权限。（若您的设备/操作系统支持）您随时可以自主地决定是否开启或关闭上述功能。
        请您注意，一旦您开启了相关权限或提交了相关信息，即视为您已主动向本平台授权收集和使用上述信息。如果您关闭了相关权限，即代表您取消了这些授权，本平台将不再继续收集使用您的这些个人信息。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。
        （二）因本平台向您提供产品与/或服务时所必需的
        在您使用本平台的产品与/或服务时，应产品或服务的功能本身、改进产品或服务的功能以及保障产品或服务的安全、合规。本平台可能会收集、保存和使用下列与您有关的信息来实现上述的功能，如果您不提供相关信息，您将无法享受本平台的产品与/或服务。这些功能主要包括：
        (1) 用户注册、登录
        您在使用本平台提供的绝大部分产品与/或服务前，需要先注册成为本平台的用户。在您注册成为本平台的用户时，您需要至少向本平台提供您的昵称、密码、您本人的手机号码或您的邮箱地址，本平台将通过发送短信验证码或邮件验证的方式验证您的身份是否有效。
        您也可以使用第三方账号（如微信、QQ等）登录，您此时将授权本平台获取您在第三方平台注册的公开信息（头像、昵称等），并在您同意本隐私政策后将您的第三方账号与您的本平台账户绑定，使您可以通过第三方账号直接登录并使用本平台的产品与/或服务。
        (2) 信息检索和信息推送
        您为了快速检索一些信息，例如课程信息、文章信息等，本平台可能会收集您输入的查询关键词和个人设备信息以优化本平台向您提供的服务体验。
        同时，本平台可能会收集并使用下列信息：
        （1）关注、收藏、搜索、浏览偏好等您的操作、使用行为信息；
        （2）反馈、发布、点赞、评论等您主动提供的信息；
        （3）获得您的明示同意后的地理位置信息。
        上述信息与来自本平台的其他服务的信息结合，进行综合统计并通过算法做特征与偏好分析，用以向您进行个性化推荐、展示或推送您可能感兴趣的特定音视频、课程等信息，或者推送更合适您的特定功能或服务。
        本平台收集、使用上述信息时进行了去标识化处理，数据分析仅对应特定的、无法直接关联用户身份的编码，无法也绝不会与您的真实身份相关联。
        (3) 购买支付
        当您准备购买本平台提供的商品或服务时，或您准备购买由本平台的关联公司、合作伙伴/机构提供的商品或服务时，本平台的系统会生成您的购买订单。此过程可能会要求您填写收货人姓名、收货地址、联系方式，同时订单中会载明您的订单号、购买的商品或服务信息、应当支付的金额和支付方式。
        上述信息构成您的订单信息，本平台会使用您的订单信息来进行身份核验、确定交易、支付结算、配送、咨询售后，本平台还会使用订单信息来判断您的交易是否存在异常以保护您的交易安全。
        (4) 课程学习
        您使用本平台或本平台的关联公司、合作伙伴/机构提供的课程、辅导、学习服务时，本平台可能会记录您的学习信息，主要包括：您的基础知识信息、测验结果、做题结果、学习时长、提问内容、作业信息、语音图文交流信息、学习偏好等。本平台将应用这部分信息分析您的学习状态，为您或为本平台的服务提供优化参考依据。
        (5) 客服售后
        本平台的系统或客服人员、课程顾问会通过用户注册时或接受服务时所提供的联系信息（包括但不限于电子邮件地址、联系电话、联系地址、即时聊天工具账号等），通过电子邮件、电话、短信、邮寄、即时聊天、弹出页面等方式跟用户取得联系。
        (6) 服务改进
        本平台可能会收集您的个人信息进行数据分析用以为您提供个性化定制的服务，或用其为本平台的服务进行未来的改进和调整。
        (7) 开展营销活动
        当你选择参加本平台或本平台的关联公司、合作伙伴/机构举办的有关营销活动时，根据活动需要你可提供姓名、通信地址、联系方式、银行账号等信息。这些信息中可能包含个人敏感信息（如个人电话号码、银行账号等），是你收到转账或者礼品所必要的，如果你拒绝提供这些信息，本平台将可能无法向你转账或发放礼品。
        (8) 保障安全
        为提高您使用本平台的产品与/或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，本平台可能会通过了解您的浏览信息、订单信息、您常用的软件信息、设备信息、用户行为信息等手段来判断您的账号风险，并可能会记录一些技术判断为异常的链接地址；本平台也会收集您的设备信息用作问题进行分析，统计流量并排查可能存在的风险。
        （三）收集、使用信息且无需征得您的同意的情形
        您充分知晓，在以下情形中本平台收集、使用个人信息无需征得您的授权和同意：
        (1) 与国家安全、国防安全有关的； (2)
        与公共安全、公共卫生、重大公共利益有关的； (3)
        与犯罪侦查、起诉和审判等有关的； (4)
        有充分证据证明您存在主观恶意或滥用权利的； (5)
        响应您的请求将导致其他个人、组织的合法权益受到严重损害的； (6)
        出于维护个人信息主体或其他个人的生命、财产等重大合法权益，但又很难征得本人同意的；
        (7) 信息主体主动向社会公众公开的； (8)
        用于维护所提供的产品与/或服务的安全稳定运行所必需的； (9)
        应国家有权机关的要求的； (10) 法律法规规定的其他情形。
        （四）来自于第三方的个人信息
        本平台可能从第三方获取您授权共享的个人信息，例如： (1)
        从第三方登录带来您的头像、第三方登录账号、昵称等信息，并在您同意本政策后将这部分信息与您的账号绑定。
        (2)
        用户因使用本平台的产品或者服务而被本平台收集的信息，例如其他用户发布的信息中可能含有你的部分信息（如：在评论、留言、发布图文、音视频中涉及到与你相关的信息）。
        (3)
        在你使用本平台产品及相关服务提供的身份认证功能时，本平台会根据相关法律法规规定和/或该身份认证功能（例如：芝麻认证）所必需，本平台将采用行业内通行的方式及尽最大的商业努力来保护你个人敏感信息的安全。如果你不提供这些信息，你将可能无法获得相关服务。
        本平台将会依据与第三方的协议，对个人信息来源的合法性进行确认后，在符合相关法律法规的前提下使用您的这些个人信息。
        （五）个人信息的匿名化使用
        在收集您的个人信息后，本平台将通过技术手段对数据进行匿名化处理，匿名化处理的信息将无法识别主体。请您了解并同意，在此情况下本平台有权使用已经匿名化的信息；并在不透露您个人信息的前提下，本平台有权对用户数据库进行分析并予以商业化的利用。
        二、本平台如何存储和保护个人信息
        （一）除非另有约定或法律另有规定，本平台在中华人民共和国境内收集和产生的个人信息将储存在中华人民共和国境内。对在中华人民共和国境外收集和产生的个人信息，本平台将按照中华人民共和国认可效力的法律、法规、国际规则或国际惯例予以存储。
        （二）本平台向您承诺，本平台将使用以下措施，使得信息安全保护达到合理的水平：
        (1)技术措施
        本平台会采用符合行业标准的安全保护措施，包括对网络服务采用传输层安全协议等加密技术，确保用户数据在传输过程中的安全，并通过隔离技术对存储的数据进行隔离。
        在部分使用您个人信息的场景中（例如个人信息展示、个人信息确认等），本平台会采用包括内容替换、单向哈希在内等多种数据脱敏技术增强您的个人信息在使用中的安全性。
        本平台亦采用了严格的访问权限控制、多因子身份认证等技术保护您个人信息，避免数据被违规获取。
        (2)访问规范
        本平台建立了信息安全制度、数据分级管理规范、安全/开发规范等制度规范来管理您个人信息的存储、访问和使用。
        本平台对信息接触者签署了保密协议，并应用了访问日志、行为监控和专门的审计措施来对数据进行全面的管控。
        (3)其他保护方式
        在员工安全意识培养上，本平台会定期举办安全、隐私相关的宣讲培训、考试。
        本平台也只允许必要知晓这些信息的本平台及本平台关联公司的员工、合作伙伴访问、接触个人信息，并适用上文提到的所有技术措施和规范限制。本平台要求相关的人员必须履行相关的保密义务，如果未能履行这些义务，则其可能会被解雇或中止与本平台的合作关系，直至追究其法律责任。
        （三）互联网环境不是绝对安全的环境，您通过各种交流方式与其他用户的交流无法确定是否已经得到了适当的保护。本平台建议您在使用其他交流工具时，注意保护个人信息安全，不要泄露个人信息或您的账户私密信息，同时在相关工具上启用复杂的密码。
        （四）请您理解，由于技术水平限制及可能存在的各种恶意手段，有可能因为本平台可控范围外的因素而出现问题。根据法律法规的要求，在不幸发生个人信息安全事件后，本平台及时向您告知：安全事件的基本情况和可能的影响；本平台已经采取或即将采取的处置措施；您可以自主防范和降低风险的建议；对您的补救措施等。本平台同事会及时将事件相关情况以邮件、信函、电话、推送通知、站内消息等方式告知您。如果难以逐一告知个人信息主体时，本平台会采取合理、有效的方式发布公告，并按照监管部门的要求，主动上报个人信息安全事件的处置状况。
        （五）本平台谨此特别提醒你，本隐私政策提供的个人信息保护措施仅适用于本平台及相关服务。一旦你离开本平台及相关服务，浏览或使用其他第三方网站、服务及内容资源，我们即没有能力及义务保护你在本平台软件及相关服务之外的软件、网站提交的任何个人信息，无论你登录、浏览或使用上述软件、网站是否基于本平台的链接或引导。
        三、本平台如何使用Cookie和同类技术
        在您使用本平台的产品与/或服务时，本平台可能会通过数据文件标识您的身份，这些向您设备发送的一个或多个数据文件是唯一的，只能被本平台的服务器读取和使用。这些数据文件可能是Cookie/Flash
        Cookie或其他类似的技术。向您发送这类数据文件的目的，是为了简化您的登录步骤、存储您的相关偏好设置、帮助判断您的登录状态和为了保卫您的账号、数据安全等。
        您可以根据自己的偏好或浏览器、操作系统的设置自行管理或删除这类数据文件。例如，您可以清除计算机上保存的Cookie，您也可以设置浏览器拒绝接受Cookie等。一旦您实施了这个“清除”操作，则在某些情况下您可能会被重复的要求验证身份，或者影响您安全访问本平台的产品与/或服务。
        四、本平台如何共享、转让、公开披露个人信息 (一) 个人信息的共享、转让
        本平台不会向第三方共享、转让你的个人信息，除非经过你本人事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。
        1、在下列情况下，本平台可能会共享的个人信息
        （1）为实现程序化广告推送，本平台可能会与关联公司或第三方合作伙伴共享去标识化或匿名化处理后的信息。
        本平台可能与关联公司或第三方合作伙伴共享信息，但本平台不会分享用于识别你个人身份的信息，例如你的姓名、身份证号等，仅会向这些合作伙伴提供难以识别你个人身份的用户画像标签及去标识化或匿名化后的统计类信息，以帮助其在不识别你个人身份的前提下提升推送、广告有效触达率。
        （2）帮助你参加营销推广活动
        当你选择参加本平台举办的有关营销活动时，根据活动需要你可提供姓名、通信地址、联系方式、银行账号等信息，以便第三方能及时向你提供奖品。当你选择参加本平台举办的有关营销活动时，根据活动需要你可提供姓名、通信地址、联系方式、银行账号等信息。经过你的明示同意，本平台会将上述信息与第三方共享，以便本平台能委托第三方及时向你提供奖品。
        （3）为实现特定功能而与业务合作伙伴共享。
        当软件服务提供商、智能设备提供商或系统服务提供商与本平台联合为你提供服务时，例如你需要使用地理位置功能时，为实现这一功能，本平台可能会收集你的位置信息及相关设备信息（例如硬件型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC））经过去标识化后并提供给前述提供商。你可以拒绝提供此类信息，但不影响你使用其他服务。
        2、对共享个人信息第三方主体的谨慎评估及责任约束。
        （1）经你同意，本平台只会与第三方共享实现目的所必要的信息。如果第三方因业务需要，确需超出前述授权范围使用个人信息的，该第三方将需再次征求你的同意。
        （2）对本平台与之共享你个人信息的第三方，该些第三方会与本平台签订保密协议。同时，本平台会对其数据安全能力与环境进行评估并要求第三方以不低于本隐私政策所要求的保密和安全措施来处理该些信息。
        3、收购、兼并、重组时个人信息的转让。
        随着本平台业务的持续发展，本平台将有可能进行合并、收购、资产转让，你的个人信息有可能因此而被转移。在发生前述变更时，本平台将按照法律法规及不低于本隐私政策所要求的安全标准继续保护或要求个人信息的继受方继续保护你的个人信息，否则本平台将要求继受方重新征得你的授权同意。
        （二）个人信息的公开披露
        除对违规账号、欺诈行为进行处罚公告时，本平台会披露相关账号的必要信息外，本平台不会公开披露你的信息，除非遵循国家法律法规规定或者获得你的同意。本平台公开披露你的个人信息会采用符合行业内标准的安全保护措施。
        （三）依法豁免征得同意共享、转让、公开披露的个人信息
        请你理解，在下列情形中，根据法律法规及国家标准，本平台共享、转让、公开披露你的个人信息无需征得你的授权同意
        (1)与国家安全、国防安全直接相关的；
        (2)与犯罪侦查、起诉、审判和判决执行等直接相关的；
        (3)与公共安全、公共卫生、重大公共利益直接相关的；
        (4)出于维护你或其他个人的生命、财产等重大合法权益但又很难得到你的同意的；
        (5)你自行向社会公众公开的个人信息；
        (6)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
        五、您如何管理您的个人信息
        （一）本平台将尽力保护您对于您个人信息的访问、更新、更正您个人信息的权利，除法律法规规定的情形外，您有权随时行使这个权利。
        但出于安全性和身份识别的考虑，你可能无法自主修改注册时提交的某些初始注册信息（如注册邮箱、实名认证后信息等）。
        （二）在部分场景中，您可以通过设备或操作系统的设置主动的授权或撤销授权本平台获取您的个人信息。
        请您理解，当您撤回授权或不予授权本平台获取您的这部分个人信息时，本平台无法继续为您提供必需这部分信息的对应服务。但您撤回授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
        （三）你可以根据《用户服务协议》第3.9条申请注销你的账号。在你注销账号前，我们将验证你的个人身份、安全状态、设备信息等。
        你知悉并理解，注销账号的行为是不可逆的行为，当你注销账号后，我们将删除有关你账号的一切信息或对相关信息进行匿名化处理，但法律法规另有规定的除外。
        （四）你可以按照本平台公示的制度进行投诉或举报。如果你认为你的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，你可以联系本平台官方客服提交投诉或者举报信息。我们核查后会在30日内反馈你的投诉与举报。
        在以下情形中，本平台无法对您的请求进行响应： (1)
        与国家安全、国防安全有关的； (2)
        与公共安全、公共卫生、重大公共利益有关的； (3)
        与犯罪侦查、起诉和审判等有关的； (4)
        有充分证据证明您存在主观恶意或滥用权利的； (5)
        响应您的请求将导致其他个人、组织的合法权益受到严重损害的； (6)
        应国家有权机关的要求的； (7) 法律法规规定的其他情形。
        六、本政策的适用、修订和通知
        本隐私政策是本平台统一使用的隐私条款，本平台所有产品与/或服务均适用本政策。
        在本平台部分的其他产品与/或服务中，可能会有其产品与/或服务特定的隐私政策，这些特定的隐私政策将更具体地说明本平台在其中如何使用您的信息，且这些特定的隐私政策是本政策不可分割的一部分；如果这些产品与/或服务中特定的隐私政策与本政策有不一致之处，以该特定的产品与/或服务的隐私政策为准。
        随着本平台业务的发展，本平台的产品与/或服务会发生升级和调整，本平台可能会依据功能调整、使用规则变更、适用法律法规变更或监管要求变更等的情形适时对本政策进行修订。
        一旦本平台对本政策进行了修订，本平台会通过本平台的产品与/或服务向您提示相关内容的更新。
        对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信、私信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。同时，也请您关注本平台以便及时了解最新的隐私政策。
        如果您在本政策修订后继续使用本平台的产品与/或服务，则表示您已经充分阅读、理解并接受修订后的隐私政策，且愿意接受修订后政策的约束，否则您将无法继续使用本平台的产品与/或服务。如果更新的内容需要采集音视频、获取你通讯录、联系方式、地理位置等个人敏感信息，仍会再次以显著方式征求你的同意。
        七、关于未成年人信息的保护
        本平台十分重视未成年人个人信息的保护。如果您是年龄未满18周岁的未成年人，您应当请您的父母或监护人阅读本政策。在征得您父母或监护人明确或书面同意本隐私政策内容前，您应当停止使用本平台的产品与/或服务。如您的父母或监护人不同意您按照本政策使用本平台的产品与/或服务或向本平台提供信息，请您立即终止使用本平台的产品与/或服务，并及时通知本平台，以便本平台采取相应的措施。
        八、术语释义
        （一）本平台的产品与/或服务是指湖北海星信息技术有限公司及其关联方通过合法拥有并运营的、标注名称为“本平台”的APP应用程序以及“本平台”官方网站（www.haixingkt.com），向你提供的产品与服务，包括但不限于视频直播、短视频发布、图文信息发布、浏览及推荐等功能。
        （二）本政策所称个人信息是指，以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，主要包括：
        (1)
        个人基本信息（包括个人姓名、生日、性别、地址、电话号码、电子邮箱、面部特征）；
        (2) 个人身份信息（身份证等有效证件信息）； (3)
        网络身份标识信息（包括账号名、账号昵称、IP地址、邮箱地址、前述相关的密码、口令、密保信息等）；
        (4)
        个人财产信息（交易记录、消费记录、申请信贷记录、银行卡信息，以及资金账户余额（如有）、积分、参加的课程等虚拟财产信息）；
        (5) 个人上网记录（网站浏览记录、软件使用记录、点击记录等）； (6)
        个人社交信息（通信录、好友关注等） (7)
        个人设备信息（包括硬件型号、设备硬件参数、操作系统类型和基本信息、软件唯一设备识别码、SIM卡信息等在内的个人常用设备信息）；
        (8) 个人位置信息（包括位置信息、运动记录等）。
        （三）个人敏感信息是指，一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策中的个人敏感信息包括：包括个人身份信息（身份证件号码，包括身份证、军官证、护照、驾驶证）；面部特征；财产信息、交易信息；网络身份识别信息（包括账号名、账号昵称、邮箱地址以及与前述有关的密码与密码保护问题和答案）；其他信息（包括通信录、个人电话号码、行程信息、精准定位信息）。
        （四）去标识化是指，通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。
        （五）匿名化是指，通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。
        如对本隐私政策内容有任何疑问、意见或建议，你可通过登录本平台官方网站(www.haixingkt.com)或者通过本平台APP的“建议与投诉”页面联系我们的官方客服与我们联系。
        （完）</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible2 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="concat">
      <div class="logins" v-if="logintype == 1">
        <div class="flex" style="justify-content: left">
          <div
            class="zedong"
            :class="selected === 'option1' ? 'active' : ''"
            @click="selectOption('option1')"
          >
            账号登录
          </div>
          <div style="margin: 0 15px">|</div>
          <div
            class="zedong"
            :class="selected === 'option2' ? 'active' : ''"
            @click="selectOption('option2')"
          >
            验证码登录
          </div>
        </div>
        <div class="inputs">
          <div v-if="selected == 'option1'">
            <el-form
              :model="user"
              ref="userLogin"
              :rules="userRules"
              :inline="false"
            >
              <el-form-item label="" prop="account">
                <div style="width: 350px; margin: 20px 0">
                  <el-input
                    v-model="user.account"
                    placeholder="输入您的账号"
                    prefix-icon="el-icon-user-solid"
                    size="medium"
                  >
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <div style="width: 350px">
                  <el-input
                    v-model="user.password"
                    show-password
                    placeholder="输入您的密码"
                    prefix-icon="el-icon-unlock"
                    size="medium"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="selected == 'option2'">
            <el-form
              :model="codeLoginData"
              ref="codeLogin"
              :rules="codeLoginRules"
              :inline="false"
            >
              <el-form-item label="" prop="phone">
                <div style="width: 350px; margin: 20px 0">
                  <el-input
                    v-model="codeLoginData.phone"
                    placeholder="输入您的手机号"
                    prefix-icon="el-icon-user-solid"
                    size="medium"
                  >
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item prop="code">
                <div class="flex">
                  <div style="width: 350px">
                    <el-input
                      v-model="codeLoginData.code"
                      placeholder="输入验证码"
                      prefix-icon="el-icon-unlock"
                      size="medium"
                    >
                    </el-input>
                  </div>
                  <div class="yanzehng2" @click="getCode()">
                    {{ Countdown }}
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="dangle" @click="handleSubmit">登录</div>
          <div class="regale" @click="handleRegister">免费注册</div>
          <div
            style="margin: 30px 0 20px 220px; cursor: pointer"
            @click="logintype = 2"
          >
            忘记密码?
          </div>
          <div class="flex" style="justify-content: space-between">
            <div style="cursor: pointer" @click="dialogVisible1 = true">
              阅读用户注册协议
            </div>
            <div
              style="cursor: pointer; margin-right: 30px"
              @click="dialogVisible2 = true"
            >
              阅读用户隐私协议
            </div>
          </div>
        </div>
      </div>
      <div class="logins" v-if="logintype == 2">
        <div style="font-size: 25px; font-weight: 700">忘记密码</div>
        <div class="flex" style="margin-top: 30px">
          <div style="width: 350px">
            <el-input placeholder="输入手机号" prefix-icon="el-icon-phone">
            </el-input>
          </div>
          <div class="yanzehng2" @click="getCode()">{{ Countdown }}</div>
        </div>
        <div class="flex" style="margin-top: 30px">
          <div style="width: 350px">
            <el-input placeholder="输入验证码" prefix-icon="el-icon-unlock">
            </el-input>
          </div>
          <div style="width: 135px"></div>
        </div>
        <div class="dangle" style="">找回</div>
        <div style="margin-top: 30px">
          请输入注册时填写的手机号，系统会向您的手机号发送验证码和新密码，请注意查收！
        </div>
      </div>
    </div>
    <footWorld></footWorld>
  </div>
</template>

<script>
import headerWorld from "@/components/headerWorld.vue";
import footWorld from "@/components/footWorld";
import {
  accountLoginApi,
  getUserInfoApi,
  smsCodeLoginApi,
} from "@/common/api/user";
import { mapMutations } from "vuex";
import { sendCodeApi } from "@/common/api/register";
// import rsa from '@/utils/rsa';
export default {
  components: {
    headerWorld,
    footWorld,
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[0-9]{10}$/.test(this.codeLoginData.phone)) {
          callback(new Error("请填写正确手机号码"));
          return false;
        } else {
          callback();
        }
      }
    };
    return {
      selected: "option1",
      dialogVisible1: false,
      dialogVisible2: false,
      getCodeisWaiting: false,
      Countdown: "获取验证码",
      logintype: 1,
      user: {
        account: "",
        password: "",
      },
      codeLoginData: {
        code: "",
        phone: "",
      },
      userRules: {},
      codeLoginRules: {
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapMutations({
      SET_ACCOUNT: "SET_ACCOUNT",
    }),
  },
  mounted() {
    const nameParam = this.$route.query.name;
    if (nameParam == "mica") {
      this.logintype = 2;
    }
  },
  methods: {
    selectOption(option) {
      this.selected = option;
    },
    handleSubmit() {
      if (this.selected === "option1") {
        this.userPasswordLogin();
      } else {
        this.userCodeLogin();
      }
    },

    async userPasswordLogin() {
      const { password, account } = this.user;
      const res = await accountLoginApi({
        account,
        password: password,
      });
      if (res.code == 0) {
        this.$router.push("/");
        localStorage.setItem("token", res.result);
        const data = await getUserInfoApi();
        this.$store.commit("SET_ACCOUNT", data.result.account);
      }
    },
    handleRegister() {
      this.$router.push("/regIndex");
    },
    userCodeLogin() {
      this.$refs.codeLogin.validate(async (valid) => {
        if (valid) {
          const res = await smsCodeLoginApi({
            ...this.codeLoginData,
          });
          if (res.code == 0) {
            this.$router.push("/");
            localStorage.setItem("token", res.result);
            const data = await getUserInfoApi();
            this.$store.commit("SET_ACCOUNT", data.result.account);
          }
        }
      });
    },

    getCode() {
      //   避免重复点击
      if (this.getCodeisWaiting) {
        return;
      }
      this.$refs.codeLogin.validateField("phone", (errorMsg) => {
        if (!errorMsg) {
          this.Countdown = "发送中...";
          this.getCodeisWaiting = true;
          this.sendCode();
        }
      });

      //   this.sendCode(this.user.phone);
    },
    async sendCode() {
      const res = await sendCodeApi({ phone: this.codeLoginData.phone });
      if (res) {
        this.setTimer();
      }
    },
    setTimer() {
      let holdTime = 59,
        _this = this;
      _this.Countdown = "重新获取(60)";
      _this.Timer = setInterval(() => {
        if (holdTime <= 0) {
          _this.getCodeisWaiting = false;
          _this.Countdown = "获取验证码";
          clearInterval(_this.Timer);
          return;
        }
        _this.Countdown = "重新获取(" + holdTime + ")";
        holdTime--;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.concat {
  background: url("https://www.haixingkt.com/Public/Default/images/login.png")
    no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.logins {
  position: absolute;
  bottom: 30px;
  right: 150px;
  background: #ffffff;
  width: 410px;
  height: 500px;
  padding: 30px;
}

.zedong {
  position: relative;
  cursor: pointer;
  padding-bottom: 5px;
}

.zedong:hover {
  color: #1584f6;
}

.zedong.active {
  color: #1584f6;
}

.zedong.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #1584f6;
  transition: height 0.3s ease;
}

.inputs {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-left: 30px;
}

.dangle {
  background-color: #1584f6;
  border: 0;
  width: 350px;
  height: 50px;
  line-height: 50px;
  display: block;
  margin-top: 40px;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.dangle:hover {
  background-color: #94d3ff;
}

.regale {
  background-color: #d9dbdd;
  border: 0;
  width: 350px;
  height: 50px;
  line-height: 50px;
  display: block;
  margin-top: 20px;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.regale:hover {
  background-color: #acb2b9;
}
.yanzehng {
  background: #1584f6;
  width: 100px;
  height: 40px;
  line-height: 40px;
  border: 0;
  font-size: 15px;
  outline: none;
  margin-left: 15px;
  cursor: pointer;
  color: #fff;
}
.yanzehng2 {
  background: #1584f6;
  width: 120px;
  text-align: center;
  margin-left: 15px;
  height: 40px;
  color: #ffffff;
  line-height: 40px;
}
</style>
